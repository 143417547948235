<template>
	<div class="footDesc">
		<van-nav-bar v-if="isMobile" :title="$t('footer.useManual')" left-arrow @click-left="onClickLeft" />
		<van-cell-group :border="false">
		  <van-cell :title="$t('footer.aboutus')" is-link to="/pages/about" />
		  <van-cell :title="$t('footer.contact')" is-link to="/pages/contact" />
		  <van-cell :title="$t('footer.service')" is-link to="/clauseService" />
		  <van-cell :title="$t('footer.refund')" is-link to="/pages/refund" />
		  <van-cell :title="$t('footer.userService')" is-link to="/clauseUser" />
		  <van-cell :title="$t('footer.companyLabel')" :value="$t('footer.companyValue')" />
		  <van-cell :title="$t('footer.licenseNumLabel')" :value="$t('footer.licenseNumValue')" />
		  <van-cell :title="$t('footer.legalPersonLabel')" :value="$t('footer.legalPersonValue')" />
		  <van-cell :title="$t('footer.telLabel')" :value="$t('footer.telValue')" />
		  <van-cell :title="$t('footer.businessNumLabel')" :value="$t('footer.businessNumValue')" />
		  <van-cell :title="$t('footer.emailLabel')" :label="$t('footer.emailValue')" />
		  <!-- <van-cell :title="$t('footer.bankLabel')" :label="$t('footer.bankValue')" /> -->
		  <van-cell :title="$t('footer.addressLabel')" :label="$t('footer.addressValue')" />
		  <van-cell :value="$t('footer.support')" />
		</van-cell-group>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import useConfigStore from '@/store/modules/config'
	
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const content = ref('')
	
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	
	onMounted(() => {
		
	})
	const onClickLeft = () => {
		history.back()
	}
</script>

<style>
</style>